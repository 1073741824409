<template>
    <div id="login_app_div">
          <van-overlay :show="load_show" >
             <div style="position: absolute;top: 45%;left: 44%;">
                <van-loading size="24px"  color="white" vertical>加载中...</van-loading>
             </div>
        </van-overlay>
        <div class="label_box">
        </div>
        <div class="register_div" v-show="show">
            <div class="tab_body">
                <div class="tab_item">
                   <van-field v-model="loginForm.mobile" type="tel" label="手机号" placeholder="请输入手机号" />
                </div>
                <div class="tab_item">
                    <van-field
                        v-model="loginForm.code"
                        center
                        clearable
                        label="短信验证码"
                        maxlength="6"
                        placeholder="请输入短信验证码"
                        >
                        <template #button>
                            <van-button size="small" @click="login_code" type="primary" :disabled="code_btn"    >{{code_msg}}</van-button>
                        </template>
                    </van-field>
                </div>
            </div>
            <div class="tab_footer">
                <div style="height:30px;"></div>
                <div style="padding:0px 10px;">
                    <van-button type="primary" @click="userLogin" class="login_btn" block>验证登录</van-button>
                </div>
                <div style="height:20px;"></div>
                <div style="padding:0px 10px;color:white;font-size:14px;">
                    <van-checkbox-group v-model="user_radio" direction="horizontal">
                        <van-checkbox name="a" shape="square"></van-checkbox>
                        我已阅读并同意<a href="javascript:void(0)" @click="showUserContract">用户协议</a>和<a href="javascript:void(0)" @click="showUserContract">隐私政策</a>
                    </van-checkbox-group>
                </div>
            </div>
          
        </div>
    </div>
</template>
<script>
import { Button,Loading,Overlay,Checkbox, CheckboxGroup,Field} from 'vant';
import {userInfo,indexActive} from '../../store/provider'
import {tokenStore,loginUrlSotre} from '../../store/token'
import util from '../../helper/util'
import {sendRegisterCode,providerReg,wxLogin} from '../../api/login.js'
import {codeToOpenid} from '../../api/wxAuth'

export default ({
    name:'mAgentHome',
    components: {
        [Button.name]: Button,
        [Loading.name]: Loading,
        [Overlay.name]: Overlay,
        [Field.name]: Field,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
    },
    data(){
        return {
            active: 0,
            user_radio:[],
            load_show:false,
            register_tab_acitve:true,
            login_tab_acitve:false,
            openId:'',
            show:true,
            code_interval:undefined,
            code_second:60,
            code_btn:false,
            register_btn_class:false,
            code_msg:"获取验证码",
            go_index:'',
            registerForm:{
                name:'',
                name_err:'请输入姓名',
                mobile:'',
                mobile_err:'请输入手机号码',
                code:'',
                code_err:'请输入验证码',
            },
            loginForm:{
                mobile:'',
                mobile_err:'请输入手机号码',
                code:'',
                code_err:'请输入验证码',
            }
        }
    },
    methods:{
        userLogin(){
            if(!this.loginForm.mobile || !this.loginForm.code){
                this.$toast("请填写登录信息")
                return;
            }
            if(this.user_radio.length==0){
                this.$toast("请勾选查看用户协议及隐私政策")
                return;
            }
            var body = {
                "mobile": this.loginForm.mobile,
                "code": this.loginForm.code,
                "openId":this.openId
            }
            this.registerOrLogin(body);
        },
        showUserContract(){
            this.$dialog.alert({
            message: '用户协议及隐私声明',
            theme: 'round-button',
            }).then(() => {
            });
        },
        mobileBlur(){
            if(!util.checkPhone(this.loginForm.mobile)){
                this.$toast({
                message: '手机号码格式错误',
                position: 'top'});
            }            
        },       
        registerOrLogin(body){
            this.$toast.loading({duration: 0,forbidClick: true,message: '加载中',});
            providerReg(body).then(res=>{
                this.$toast.clear();
                if(res.status){
                    this.$toast(res.message||"登录成功");
                    var data = res.data;
                    data.name = data.realName;
                    //保存token和用户信息
                    tokenStore.set('Bearer '+res.data.token)
                    delete data.token
                    userInfo.set(data);
                    debugger
                    if(util.isIOS()){
                         window.location.href = window.location.origin+this.go_index;
                    }else{
                        this.$router.replace({path:this.go_index});
                    }
                }else{
                    this.$toast(res.message);
                }
            }).catch(err=>{
                console.log(err)
                this.$toast.clear();
                this.$toast.fail("注册失败");
            });
        },
        login_code(){
            this.mobileBlur();
            if(!util.checkPhone(this.loginForm.mobile)){
                this.loginForm.mobile_err = "手机号码格式错误";
                return;
            }
            if(!this.code_interval){
                sendRegisterCode({mobile:this.loginForm.mobile}).then((res)=>{
                   this.$toast(res.message);
                });
                this.code_btn = true;
                this.code_interval = setInterval(() => {
                    this.code_msg ="重新发送"+ this.code_second--;
                    if(this.code_second<=0){
                        this.code_msg = "获取验证码";
                        this.code_btn = false;
                        window.clearInterval(this.code_interval);
                        this.code_second = 60;
                        this.code_interval= undefined;
                    }
                }, 1000);
            } 
        },     
        login(openid){
            this.load_show = true;
             wxLogin({openId:openid,state:1}).then(res=>{
                this.load_show = false;
                if(res.id){                    
                    var data = res;
                    data.name = data.realName;
                    //保存token和用户信息
                    tokenStore.set('Bearer '+data.token)
                    delete data.token
                    userInfo.set(data);
                    if(util.isIOS()){
                        window.location.href = window.location.origin+this.go_index +`?wasteJoinStatus=${data.wasteJoinStatus}`;
                    }else{
                        this.$router.replace({path:this.go_index,query:{wasteJoinStatus:data.wasteJoinStatus}});
                    }
                    // this.$router.replace({path:this.go_index,query:{wasteJoinStatus:data.wasteJoinStatus}});
                }else{
                    this.show = true;
                }
            });
        },
        getCode () { // 非静默授权，第一次有弹框
            this.code = ''
            var local = window.location.href // 获取页面url
            var appid = this.$enum.wx_appid;//this.$enum.wx_appid;
            var obj = this.getUrlCode();
            this.code = obj.code // 截取code
            this.state = obj.state 
           
            if (!this.code) { // 如果没有code，则去请求
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;
            } else {
                this.$toast.loading({duration: 0,forbidClick: true,message: '微信授权中',});
                codeToOpenid({code:this.code})
                .then(res=>{
                    this.$toast.clear();
                    // console.log(res)
                    res =res.data;
                    if(res.code == 500){
                        this.$toast.fail(res.msg);
                    }else{
                        if(!res.data){
                            this.$toast.fail('请求错误，请刷新重试！');
                            return;
                        }
                        this.openId = res.data;
                        window.localStorage.setItem('openId', this.openId);
                        this.login(this.openId);
                    }
                })
            }
        },
        getUrlCode() { // 截取url中的code方法
            var url = location.search
            this.winUrl = url
            var theRequest = new Object()
            if (url.indexOf("?") != -1) {
                var str = url.substr(1)
                var strs = str.split("&")
                for(var i = 0; i < strs.length; i ++) {
                    theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
                }
            }
            return theRequest
        },
    },
    mounted(){       
        loginUrlSotre.set('/mAgentHome');
        this.openId = window.localStorage.getItem('openId');
        if(this.$global.isWeiXin){
            if(!this.openId){ // 如果缓存localStorage中没有微信openId，则需用code去后台获取
                this.getCode();
            }else{
                this.login(this.openId);
            }
        }
        setTimeout(() => {
            this.register_btn_class = true;
        }, 1000);
    },
    created(){
        this.active = this.$route.query.active||1;
        indexActive.set(this.active);
        var index = this.$route.query.go_index;
        this.go_index = index||'/mindex';
    }
})
</script>
<style scoped>
@import './mAgentHome.css';
</style>